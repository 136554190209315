import { Injectable } from '@angular/core';
import { AWSAppSyncClient } from 'aws-appsync';
import { environment } from '../../../environments/environment';
import { AUTH_TYPE } from 'aws-appsync-auth-link/lib/auth-link';

@Injectable({
  providedIn: 'root'
})
export class MatrixService {
	client: any;

	constructor() {
		const awsClient = new AWSAppSyncClient({
			url: environment.aws_appsync_matrix,
			region: environment.aws_appsync_region,
			auth: {
				type: AUTH_TYPE.API_KEY,
				apiKey: environment.aws_appsync_matrix_authentication_value
			},
			disableOffline: true
		});
		this.client = awsClient as AWSAppSyncClient<any>;
	}

	getHydrated(): Promise<AWSAppSyncClient<any>> {
		return this.client.hydrated();
	}
}
