import {NgModule} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {StoreModule} from '@ngrx/store';
import {SharedModule} from './shared/shared.module';
import {MaterialModule} from './shared/modules/material.module';
import {metaReducers, reducers} from './_state/reducers/index';
import {AppEffects} from './_state/effects/app.effects';
import {ApiCoreService} from './shared/services/api-core.service';
import {MatrixService} from './shared/services/matrix.service';
import {NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER,} from 'ngx-ui-loader';
import {HttpClientModule} from '@angular/common/http';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
	bgsColor: '#2b0d61',
	bgsOpacity: 0.5,
	bgsPosition: POSITION.bottomCenter,
	bgsSize: 40,
	bgsType: SPINNER.ballSpinClockwise, // background spinner type
	fgsType: SPINNER.ballScaleMultiple, // foreground spinner type
	pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
	pbThickness: 3, // progress bar thickness
	text: '... CARGANDO',
	textColor: '#2b0d61',
	textPosition: 'center-center',
	pbColor: '#2b0d61',
	overlayColor: 'rgba(40,40,40,0.26)',
	fgsColor: '#2b0d61',
	blur: 3,
	delay: 0,
	fastFadeOut: true,
};

@NgModule({
  declarations: [
	AppComponent
  ],
  imports: [
	BrowserModule,
	AppRoutingModule,
	BrowserAnimationsModule,
	EffectsModule.forRoot([AppEffects]),
	StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
	StoreModule.forRoot(reducers, { metaReducers }),
	SharedModule,
	MaterialModule,
	HttpClientModule,
	NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
	!environment.production ? StoreDevtoolsModule.instrument() : []
  ],
	providers: [
		ApiCoreService,
		MatrixService,
		{ provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
	],
  bootstrap: [AppComponent]
})
export class AppModule { }
