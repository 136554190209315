import { PercentNumberNegativeDirective } from './directives/percent-negative-number.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


// Angular CDK
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

// Angular Material Components
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { RoutePartsService } from './services/route-parts.service';
import { ThemeService } from './services/theme.service';
import { ApiCoreService } from './services/api-core.service';
import { MatrixService } from './services/matrix.service';
import { CustomPercentPipe } from './pipes/customPercentPipe.pipe';
import { CapitalFormatPipe } from './pipes/capital-format.pipe';
import { AggregateLimitCapitalFormatPipe } from './pipes/aggregate-limit-capital-format.pipe';
import { CapitalNumberDirective } from './directives/capital-number.directive';
import { IntegerNumberDirective } from './directives/integer-number.directive';
import { IntegerFormatNumberPipe } from './pipes/integer-format-number.pipe';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { CustomCostHourDirective } from './directives/custom-cost-hours.directive';

const MaterialModule = [
	CdkTableModule,
	CdkTreeModule,
	DragDropModule,
	MatCheckboxModule,
	MatButtonModule,
	MatInputModule,
	MatAutocompleteModule,
	MatDatepickerModule,
	MatFormFieldModule,
	MatRadioModule,
	MatSelectModule,
	MatSliderModule,
	MatSlideToggleModule,
	MatMenuModule,
	MatSidenavModule,
	MatToolbarModule,
	MatListModule,
	MatGridListModule,
	MatCardModule,
	MatStepperModule,
	MatTabsModule,
	MatExpansionModule,
	MatButtonToggleModule,
	MatChipsModule,
	MatIconModule,
	MatProgressSpinnerModule,
	MatProgressBarModule,
	MatDialogModule,
	MatTooltipModule,
	MatSnackBarModule,
	MatTableModule,
	MatSortModule,
	MatPaginatorModule,
	ScrollingModule
];

@NgModule({
	declarations: [
		CustomPercentPipe,
		CapitalFormatPipe,
  		AggregateLimitCapitalFormatPipe,
  	 	CapitalNumberDirective,
    	IntegerNumberDirective,
     	IntegerFormatNumberPipe,
    	OnlyNumbersDirective,
	 	CustomCostHourDirective,
		PercentNumberNegativeDirective
	],
	imports: [
		CommonModule,
		MaterialModule
	],
	exports: [
		MaterialModule,
		CustomPercentPipe,
		CapitalFormatPipe,
	    AggregateLimitCapitalFormatPipe,
		CapitalNumberDirective,
		IntegerNumberDirective,
		IntegerFormatNumberPipe,
		OnlyNumbersDirective,
		CustomCostHourDirective,
		PercentNumberNegativeDirective
	],
	providers: [
		ThemeService,
		RoutePartsService,
		ApiCoreService,
		MatrixService
	],
})
export class SharedModule { }
