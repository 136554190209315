export const environment = {
	production: false,
	project_risk: 'Vida Grupo',
	product: {
		risk_id: 72,
		module_id: 1,
		product_id: 2,
	},
	coreAPIName: 'CoreAPI',
	acprAPIName: 'acprAPI',
	aws: {
		aws_project_region: 'us-east-1',
		aws_cognito_identity_pool_id: 'us-east-1:594902652773',
		aws_cognito_region: 'us-east-1',
		aws_user_pools_id: 'us-east-1_UU9Z6a0n3',
		aws_user_pools_web_client_id: 'tcq53h177im86qdnodmalvfcl',
		cookieStorage: {
			domain: '.dev.protectasecuritycloud.pe',
			path: '/',
			secure: false
		},
		oauth: {}
	},
	aws_appsync_api_core: 'https://api.core.stg.protectasecuritycloud.pe/graphql',
	aws_appsync_api_core_authentication_value: 'da2-fcv7m7ecmbhxlfuz72lw2zx7ce',
	/* aws_appsync_api_core: 'https://core-api.core.prd.protectasecuritycloud.pe/graphql',
	aws_appsync_api_core_authentication_value: 'da2-kl2twvpm3bghlc5ir63wyrxbsi', */
	aws_appsync_matrix: 'https://matrix-api.tcvg.dev.protectasecuritycloud.pe/graphql',
	aws_appsync_matrix_authentication_value: 'da2-vmsky5orhneujlwn5amdrjfkza',
	aws_appsync_region: 'us-east-1',
	aws_appsync_authentication_types: 'API_KEY',
	login: 'https://login.core.dev.protectasecuritycloud.pe/#/auth/sign-in'
};
