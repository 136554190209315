import { ActionReducer, ActionReducerMap, MetaReducer, Action } from '@ngrx/store';
import { environment } from '../../../environments/environment';

export interface State {}

export function logger(reducer: ActionReducer<State>): ActionReducer<any, any> {
	return (state: State, action: Action): State => reducer(state, action);
}

export const reducers: ActionReducerMap<State> = {};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger] : [];
